{
  "name": "dmx-wetter-terminal-client",
  "version": "2.0.43",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "prebuild": "npm --no-git-tag-version version patch",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "test": "ng test"
  },
  "private": true,
  "dependencies": {
    "@angular-material-components/datetime-picker": "^16.0.1",
    "@angular/animations": "^17.0.8",
    "@angular/cdk": "^17.0.4",
    "@angular/common": "^17.0.8",
    "@angular/compiler": "^17.0.8",
    "@angular/core": "^17.0.8",
    "@angular/forms": "^17.0.8",
    "@angular/material": "^17.0.4",
    "@angular/platform-browser": "^17.0.8",
    "@angular/platform-browser-dynamic": "^17.0.8",
    "@angular/router": "^17.0.8",
    "@azure/msal-angular": "^3.0.4",
    "@azure/msal-browser": "^3.1.0",
    "firebase": "^9.23.0",
    "rxjs": "^7.8.1",
    "tslib": "^2.6.2",
    "webpack": "^5.88.2",
    "zone.js": "^0.14.2"
  },
  "devDependencies": {
    "@angular-builders/custom-webpack": "^17.0.0",
    "@angular-devkit/build-angular": "^17.0.9",
    "@angular/cli": "^17.0.9",
    "@angular/compiler-cli": "^17.0.8",
    "@types/jasmine": "~3.10.0",
    "@types/jsonpath": "^0.2.1",
    "@types/node": "^12.20.55",
    "jasmine-core": "~3.10.0",
    "karma": "~6.3.0",
    "karma-chrome-launcher": "~3.1.0",
    "karma-coverage": "~2.1.0",
    "karma-jasmine": "~4.0.0",
    "karma-jasmine-html-reporter": "~1.7.0",
    "typescript": ">=5.2 <5.3",
    "webpack-cli": "^5.1.4"
  }
}
